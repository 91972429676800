.MuiPopover-paper {
  background-color: transparent !important;
  margin-top: 5px !important;
}

:root {
  --font-family: 'Nasalization', sans-serif;
  --second-family: 'Montserrat', sans-serif;
  --content-width: 1180px;
  --container-offset: 20px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --main-color: #fff;
  --white: #fff;
  --yellow-text: #f7ef7c;
  --black-text: #032c06;
  --footer-bg: #071712;
  --gray-text: #959ba5;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: 'Nasalization';
  src: url('../fonts/../fonts/Nasalization-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/../fonts/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/../fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/../fonts/Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/../fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  display: inline-block;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

ul li {
  list-style: none;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea {
  font-family: var(--font-family, sans-serif);
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
}

input,
textarea {
  width: 100%;
}

button,
select,
option {
  cursor: pointer;
}

html,
body {
  height: 100%;
  min-width: 20rem;
}

body {
  color: var(--main-color);
  line-height: 1;
  font-family: var(--font-family, sans-serif);
  font-size: 1rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

.grayfilter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: -1;
}
.lock body::after {
  background-color: transparent;
  z-index: 10;
}

.wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.wrapper > main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.wrapper:before {
  width: 100%;
  content: '';
  position: fixed;
  z-index: -25;
  top: 0;
  bottom: 0;
  right: -0.3125rem;
  left: 0;
  width: calc(100% + 0.3125rem);
  background: url('../img/bg.jpg') top center/cover no-repeat;
}

[class*='__container'] {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 21.875rem) {
  [class*='__container'] {
    padding: 0 15px;
  }
}

.page__container {
  padding-top: 3.625rem;
  padding-bottom: 6.25rem;
}
@media (max-width: 48em) {
  .page__container {
    padding-top: 1.5625rem;
    padding-bottom: 2.5rem;
  }
}

.button {
  cursor: pointer;
  padding: 0.3125rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 60px;
  font-family: var(--font-family);
  color: #000;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  background: linear-gradient(10deg, rgba(88, 123, 84, 0.7) 0%, rgba(227, 251, 76, 0.8) 100%),
    linear-gradient(10deg, rgba(255, 255, 255, 0.24) 0%, #e3fb4c 74.74%);
  min-height: 2.6875rem;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  -webkit-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
}
.button:before {
  border-radius: inherit;
  background: linear-gradient(56deg, rgba(88, 123, 84, 0.4) 0%, rgba(227, 251, 76, 0.4) 100%),
    linear-gradient(59deg, rgba(255, 255, 255, 0.7) 0%, rgba(227, 251, 76, 0.7) 74.74%);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: -100;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.button:focus-visible,
.button:active {
  outline: none;
}
.button:focus-visible::before,
.button:active::before {
  opacity: 1;
}
@media (any-hover: hover) {
  .button:hover::before {
    opacity: 1;
  }
  p.button:hover::before {
    opacity: 0;
  }
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.375rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 31.25em) {
  .logo__icon {
    width: 1.375rem;
    gap: 0.375rem;
  }
  .logo__icon--footer {
    width: 1.625rem;
  }
}
.logo__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.375rem;
}
@media (max-width: 48em) {
  .logo__name {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    gap: 0;
  }
}
.logo__name--footer {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0.125rem;
}
@media (max-width: 31.25em) {
  .logo__name-icon {
    width: 1.6875rem;
  }
  .logo__name-icon--footer {
    width: 2.9375rem;
  }
}
.logo__name-text {
  font-size: 1.25rem;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-shadow: 0 0 24px var(--main-color);
}
@media (max-width: 31.25em) {
  .logo__name-text {
    font-size: 0.8125rem;
  }
  .logo__name-text--footer {
    font-size: 1rem;
  }
}

.text-yellow {
  color: var(--yellow-text);
}

.card {
  position: relative;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.card::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: url('../img/border-2.png') 0 0/100% 100% no-repeat;
  z-index: -1;
}
.card--my-staking::after {
  background: url('../img/border-3.png') 0 0/100% 100% no-repeat;
}
@media (max-width: 48em) {
  .card--my-staking::after {
    background: url('../img/border-2.png') 0 0/100% 100% no-repeat;
  }
}

.change-up {
  background: radial-gradient(circle, #a5ff5e 0%, #49b317 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.change-down {
  background: radial-gradient(circle, #f85858 0%, #cf1e1e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

._tabs-block {
  display: none;
}
._tabs-block._active {
  display: block;
}

.modal {
  --transition-time: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(3, 61, 44, 0.9)),
    color-stop(16.35%, rgba(47, 123, 90, 0.9)),
    color-stop(47.92%, rgba(12, 85, 53, 0.9)),
    to(rgba(40, 82, 59, 0.9))
  );
  background: linear-gradient(
    180deg,
    rgba(3, 61, 44, 0.9) 0%,
    rgba(47, 123, 90, 0.9) 16.35%,
    rgba(12, 85, 53, 0.9) 47.92%,
    rgba(40, 82, 59, 0.9) 100%
  );
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
}

.modal__container {
  padding: 1.8125rem 2.5rem 3.125rem;
  margin: 1.25rem 0.9375rem 1.875rem;
  max-width: 26.25rem;
  width: 100%;
  cursor: default;
  display: none;
  position: relative;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
}
@media (max-width: 36em) {
  .modal__container {
    padding: 1.8125rem 1.25rem 3.125rem;
    max-width: 90%;
  }
}
.modal__container::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: 6px;
  border: 2px solid transparent;
  background: radial-gradient(ellipse, rgb(21, 21, 21) 0%, rgba(21, 21, 21, 0.1) 100%),
    radial-gradient(ellipse, rgb(9, 55, 223) 0%, rgba(9, 55, 223, 0.1) 100%),
    radial-gradient(ellipse, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3) 100%);
  background-origin: border-box;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  -webkit-mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.modal__title {
  margin-bottom: 1.25rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
}

.modal__close {
  position: absolute;
  width: 2.125rem;
  height: 2.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 0rem;
  top: 0rem;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 50%;
  background: linear-gradient(56deg, #587b54 0%, #e3fb4c 100%),
    linear-gradient(59deg, rgba(255, 255, 255, 0.24) 0%, #e3fb4c 74.74%);
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
@media (max-width: 36em) {
  .modal__close {
    right: 0.6875rem;
    top: 0.6875rem;
  }
}

.modal__close-icon {
  width: 0.5rem;
  height: 0.5rem;
  fill: none;
  stroke: #000;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
}

.modal__container.modal-open {
  display: inline-block;
  transform: translate(0, -200px);
  opacity: 0;
  transition: all 0.5s ease;
}

.modal.is-open .modal__container {
  transform: translate(0, 0);
  opacity: 1;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.fade {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.8s ease;
  transition: -webkit-transform 0.8s ease;
  transition: transform 0.8s ease;
  transition: transform 0.8s ease, -webkit-transform 0.8s ease;
}

.fade.animate-open {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease,
    -webkit-transform var(--transition-time) ease;
}

.fadeInUp.animate-open {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease,
    -webkit-transform var(--transition-time) ease;
}

.custom.animate-open {
  opacity: 1;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: opacity var(--transition-time), -webkit-transform var(--transition-time);
  transition: opacity var(--transition-time), -webkit-transform var(--transition-time);
  transition: transform var(--transition-time), opacity var(--transition-time);
  transition: transform var(--transition-time), opacity var(--transition-time), -webkit-transform var(--transition-time);
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.modal__image {
  margin: 0 auto;
  margin-bottom: 0.3125rem;
  width: 8.5rem;
}
.modal__image img {
  width: 100%;
}
.modal__title {
  margin-bottom: 1.1875rem;
  font-weight: 400;
  font-size: 2rem;
  text-transform: uppercase;
  text-shadow: 0 0 34px var(--white);
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.modal__text {
  margin-bottom: 1rem;
  font-family: var(--second-family);
  text-align: center;
  line-height: 1.2;
}
.modal__text span {
  font-weight: 700;
}
@media (max-width: 21.875em) {
  .modal__text {
    font-size: 0.875rem;
  }
}
.modal__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal__form-label {
  margin-bottom: 0.3125rem;
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
}
.modal__form-control {
  margin-bottom: 0.375rem;
  padding: 0.625rem 0.9375rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 2.5rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.4);
}
.modal__form-control::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: url('../img/bg-control.png') 0 0/100% 100% no-repeat;
}
@media (max-width: 62.375em) {
  .modal__form-control {
    min-height: 3.125rem;
  }
}
@media (max-width: 21.875em) {
  .modal__form-control {
    font-size: 1rem;
    min-height: 3.125rem;
  }
}
.modal__form-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
}
.modal__form-input:focus {
  outline: none;
}
.modal__form-btn-max {
  font-family: var(--second-family);
}
.modal__form-app {
  margin-bottom: 1.125rem;
  font-family: var(--second-family);
  text-align: end;
  font-size: 0.875rem;
  font-weight: 500;
}
.modal__form-app span {
  font-weight: 700;
}
.modal__form-button {
  width: 100%;
}

.modal__unstake {
  margin-bottom: 1.4375rem;
  padding: 0.6875rem 0.75rem;
  width: 100%;
  position: relative;
  border-radius: 8px;
  min-height: 4.6875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.3125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal__unstake::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(56deg, rgba(88, 123, 84, 0.8) 0%, rgba(227, 251, 76, 0.8) 100%),
    linear-gradient(59deg, rgba(255, 255, 255, 0.24) 0%, #e3fb4c 74.74%);
  background-origin: border-box;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  -webkit-mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.modal__unstake-text {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1.25rem;
}
.modal__unstake-text span {
  font-weight: 700;
}

.header__container {
  min-height: 6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.9375rem;
}
@media (max-width: 48em) {
  .header__container {
    min-height: 4.75rem;
  }
}
.header__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2.1875rem;
}
@media (max-width: 62.375em) {
  .header__actions {
    gap: 1.5625rem;
  }
}
@media (max-width: 24.0625em) {
  .header__actions {
    gap: 0.625rem;
  }
}
.header__lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.3125rem;
  -webkit-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}
.lang_menu {
  padding: 5px 0;
  width: 100%;
  position: relative;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
  display: flex;
  flex-direction: column;
}
.lang_item {
  color: #629a78;
  padding: 10px 25px;
}
.lang_item:hover {
  color: #fff;
}
.lang_menu::before {
  content: ' ';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: 6px;
  border: 2px solid transparent;
  background: radial-gradient(ellipse, rgb(21, 21, 21) 0%, rgba(21, 21, 21, 0.1) 100%),
    radial-gradient(ellipse, rgb(9, 55, 223) 0%, rgba(9, 55, 223, 0.1) 100%),
    radial-gradient(ellipse, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3) 100%);
  background-origin: border-box;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  -webkit-mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
@media (any-hover: hover) {
  .header__lang:hover {
    color: var(--yellow-text);
  }
  .header__lang:hover .header__lang-arrow {
    fill: var(--yellow-text);
  }
}
@media (max-width: 21.875em) {
  .header__lang {
    gap: 0.1875rem;
  }
}
.header__lang.active .header__lang-arrow {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.header__lang-text {
  font-family: var(--second-family);
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
}
@media (max-width: 21.875em) {
  .header__lang-text {
    font-size: 0.8125rem;
  }
}
.header__lang-arrow {
  width: 0.625rem;
  height: 0.375rem;
  fill: var(--white);
  -webkit-transition: fill 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  transition: fill 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, fill 0.3s ease 0s;
  transition: transform 0.3s ease 0s, fill 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}
.header__button {
  width: 100%;
  width: 14.5rem;
}

@media (max-width: 48em) {
  .header__button {
    min-height: 2.25rem;
    width: 10rem;
    font-size: 0.75rem;
  }
}
@media (max-width: 21.875em) {
  .header__button {
    width: 8.75rem;
    font-size: 0.6875rem;
  }
}

.staking {
  margin-bottom: 3.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 6.25rem;
}
@media (max-width: 48em) {
  .staking {
    margin-bottom: 3.75rem;
    gap: 3.75rem;
  }
}
.staking__content {
  position: relative;
  min-height: 40.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 7px;
}
@media (max-width: 48em) {
  .staking__content {
    margin: 0 auto;
  }
  .staking__content--partnership {
    min-height: auto;
  }
}
.staking__background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -o-object-position: top center;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.staking__background img {
  width: 100%;
  height: 100%;
}
.staking__background--partnership {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
.staking__body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 3.125rem 5rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 5.3125rem;
}
@media (max-width: 68.75em) {
  .staking__body {
    padding: 3.125rem 3.125rem;
    gap: 2.5rem;
  }
}
@media (max-width: 62.375em) {
  .staking__body {
    padding: 1.875rem;
    gap: 2.5rem;
  }
}
@media (max-width: 48em) {
  .staking__body {
    padding: 2.1875rem 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 1.875rem;
  }
}
@media (max-width: 31.25em) {
  .staking__body {
    padding: 0.9375rem 1.5rem 2.1875rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 1.875rem;
  }
}
@media (max-width: 21.875em) {
  .staking__body {
    padding: 0.9375rem 0.9375rem 2.1875rem;
  }
}
@media (max-width: 48em) {
  .staking__body--partnership {
    padding: 2.1875rem 1.5rem 3.125rem;
  }
}
.staking__info {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  font-family: var(--second-family);
  display: flex;
  flex-direction: column;
}
@media (max-width: 48em) {
  .staking__info {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
.staking__info-text {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 110%;
  letter-spacing: 0.03em;
}
.staking__info-text:not(:last-child) {
  margin-bottom: 1.25rem;
}
@media (max-width: 62.375em) {
  .staking__info-text {
    font-size: 1.125rem;
  }
}
@media (max-width: 48em) {
  .staking__info-text {
    font-size: 0.875rem;
    line-height: 120%;
  }
}
.staking__info-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.8125rem;
}
.staking__info-list:not(:last-child) {
  margin-bottom: 1.75rem;
}
@media (max-width: 48em) {
  .staking__info-list {
    gap: 1rem;
  }
}
.staking__info-item {
  padding-left: 1.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 120%;
  position: relative;
}
.staking__info-item span {
  font-weight: 700;
  font-size: 0.875rem;
}
@media (max-width: 48em) {
  .staking__info-item span {
    font-size: 0.8125rem;
  }
}
@media (max-width: 48em) {
  .staking__info-item {
    font-size: 0.8125rem;
  }
}
.staking__info-item::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  background: url('../img/svg/ellipse.svg') 0 0 no-repeat;
}
.staking__actions {
  margin-top: 0.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  position: relative;
}
@media (max-width: 48em) {
  .staking__actions {
    margin-top: 0;
    height: 21.875rem;
  }
}
@media (max-width: 24.375em) {
  .staking__actions {
    height: 21.375rem;
  }
}
@media (max-width: 21.875em) {
  .staking__actions {
    height: 20rem;
  }
}
@media (max-width: 48em) {
  .staking__actions--partnership {
    display: none;
  }
}
.staking__frame {
  top: 0;
  left: 0;
  position: absolute;
  width: 102%;
  height: 0;
  padding-bottom: 103%;
  background: url('../img/card.png') 0 0/100% no-repeat;
}
@media (max-width: 62.375em) {
  .staking__frame {
    padding-bottom: 110%;
    background: url('../img/card.png') 0 0/100% 100% no-repeat;
  }
}
@media (max-width: 48em) {
  .staking__frame {
    left: 50%;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    max-width: 21.875rem;
    height: 100%;
    padding-bottom: 0;
  }
}
.staking__frame-corner {
  position: absolute;
}
.staking__frame-corner--1 {
  top: 0px;
  left: 0px;
}
.staking__frame-corner--2 {
  top: 0px;
  right: 0px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.staking__frame-corner--3 {
  bottom: 0px;
  right: 0px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.staking__frame-corner--4 {
  bottom: 0;
  left: 0px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.staking__frame-corner-line {
  position: absolute;
  width: 3.375rem;
  height: 0.0625rem;
  background-color: rgba(255, 255, 255, 0.15);
  background-blend-mode: multiply;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.2);
  top: 3px;
  left: 3px;
}
.staking__frame-corner-line--2 {
  height: 3.375rem;
  width: 0.0625rem;
}
.staking__actions-content {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: -0.3125rem;
}
.staking__actions-image {
  width: 36%;
}
.staking__actions-image:not(:last-child) {
  margin-bottom: 0.75rem;
}
@media (max-width: 68.75em) {
  .staking__actions-image:not(:last-child) {
    margin-bottom: 0.4375rem;
  }
}
.staking__actions-image img {
  width: 100%;
}
.staking__actions-image-avo {
  width: 61%;
}
.staking__actions-image-avo:not(:last-child) {
  margin-bottom: 0.75rem;
}
@media (max-width: 68.75em) {
  .staking__actions-image-avo:not(:last-child) {
    margin-bottom: 0.4375rem;
  }
}
.staking__actions-image-avo img {
  width: 100%;
}
.staking__actions-text {
  line-height: 120%;
  text-align: center;
  color: var(--footer-bg);
  max-width: 16.875rem;
  text-align: center;
}
.staking__actions-text:not(:last-child) {
  margin-bottom: 1.4375rem;
}
@media (max-width: 68.75em) {
  .staking__actions-text {
    font-size: 0.875rem;
  }
  .staking__actions-text:not(:last-child) {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 62.375em) {
  .staking__actions-text {
    max-width: 12.5rem;
  }
}
.staking__actions-btn {
  width: 14.5rem;
}
@media (max-width: 62.375em) {
  .staking__actions-btn {
    max-width: 60%;
    font-size: 0.875rem;
    min-height: 2.25rem;
  }
}

.menu__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 3.125rem;
}
@media (max-width: 48em) {
  .menu__list {
    gap: 1.875rem;
  }
}
.menu__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.menu__link {
  position: relative;
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  gap: 0.3125rem;
  min-height: 4.125rem;
  -webkit-transform: translate(0.3125rem, 0px);
  -ms-transform: translate(0.3125rem, 0px);
  transform: translate(0.3125rem, 0px);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #629a78;
  text-align: center;
}
.menu__link.active {
  -webkit-transform: translate(0.375rem, 0.5rem);
  -ms-transform: translate(0.375rem, 0.5rem);
  transform: translate(0.375rem, 0.5rem);
  pointer-events: none;
  border: 0px;
  color: var(--main-color);
  font-size: 2rem;
}
@media (max-width: 62.375em) {
  .menu__link.active {
    font-size: 1.75rem;
  }
}
@media (max-width: 48em) {
  .menu__link.active {
    -webkit-transform: translate(0rem, 0);
    -ms-transform: translate(0rem, 0);
    transform: translate(0rem, 0);
    font-size: 1rem;
  }
}
@media (max-width: 31.25em) {
  .menu__link.active {
    font-size: 0.875rem;
  }
}
@media (max-width: 23.125em) {
  .menu__link.active {
    font-size: 0.8125rem;
  }
}
@media (max-width: 62.375em) {
  .menu__link {
    font-size: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.1875rem;
  }
}
@media (max-width: 48em) {
  .menu__link {
    font-size: 1rem;
    min-height: 3.5625rem;
  }
}
@media (max-width: 31.25em) {
  .menu__link {
    font-size: 0.875rem;
  }
}
@media (max-width: 23.125em) {
  .menu__link {
    font-size: 0.8125rem;
  }
}

.staking__actions-qr {
  width: 57.5%;
  margin-bottom: 2rem;
  margin-top: 0.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.9375rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 62em) {
  .staking__actions-qr {
    margin-bottom: 1.1875rem;
  }
}
.staking__actions-qr-code {
  width: 72%;
}
@media (max-width: 73.75em) {
  .staking__actions-qr-code {
    width: 67%;
  }
}
@media (max-width: 62.375em) {
  .staking__actions-qr-code {
    margin: 0 auto;
  }
}
.staking__actions-qr-text {
  margin-right: -1.25rem;
  max-width: 5.75rem;
  font-family: var(--second-family);
  font-size: 0.75rem;
  line-height: 120%;
  color: var(--footer-bg);
}
@media (max-width: 62.375em) {
  .staking__actions-qr-text {
    display: none;
  }
}
.staking__invite {
  width: 57.5%;
}
@media (max-width: 48em) {
  .staking__invite {
    width: 63%;
  }
}
.staking__invite-label {
  display: block;
  margin-bottom: 0.375rem;
  line-height: 100%;
  color: var(--footer-bg);
}
@media (max-width: 62.375em) {
  .staking__invite-label {
    font-size: 0.875rem;
  }
}
.staking__invite-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  text-wrap: nowrap;
  gap: 0.6875rem;
  padding: 0.6875rem 0.875rem 0.875rem;
  background: var(--footer-bg);
  border-radius: 8px;
}
.staking__invite-address {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.staking__invite-btn-copy {
  width: 1.25rem;
  height: 1.25rem;
}
.staking__invite-btn-copy:active .staking__invite-btn-icon {
  stroke: var(--yellow-text);
}
@media (any-hover: hover) {
  .staking__invite-btn-copy:hover .staking__invite-btn-icon {
    stroke: var(--yellow-text);
  }
}
.staking__invite-btn-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: none;
  stroke: #fff;
  -webkit-transition: stroke 0.3s ease;
  transition: stroke 0.3s ease;
}

.referrals-info {
  padding: 1.25rem 1.625rem 1.8125rem;
  border-radius: 8px;
  background: var(--footer-bg);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
}
@media (max-width: 48em) {
  .referrals-info {
    margin: auto;
  }
}
@media (max-width: 31.25em) {
  .referrals-info {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.referrals-info__title {
  font-size: 1.25rem;
  line-height: 110%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.referrals-info__title:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.referrals-info__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.0625rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.referrals-info__item {
  position: relative;
}
.referrals-info__item:first-child::after {
  content: '';
  position: absolute;
  right: -1rem;
  top: 0;
  height: 100%;
  background-color: #263933;
  width: 1px;
}
.referrals-info__label {
  display: block;
  margin-bottom: 0.0625rem;
  font-family: var(--second-family);
  font-size: 0.875rem;
  line-height: 110%;
  letter-spacing: 0.03em;
}
.referrals-info__amount {
  font-size: 1.25rem;
  line-height: 110%;
  letter-spacing: 0.03em;
}

.staking-partnership__info-title {
  margin-bottom: 4.375rem;
  font-family: var(--font-family);
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media (max-width: 48em) {
  .staking-partnership__info-title {
    display: none;
  }
}
.staking-partnership__info-title-avo {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  text-shadow: 0 0 34px var(--white);
}
.staking-partnership__info-title-avo span {
  display: inline-block;
  letter-spacing: 0.05em;
}
.staking-partnership__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.875rem;
}
@media (max-width: 48em) {
  .staking-partnership__content {
    gap: 1.5625rem;
  }
}
.staking-partnership__text {
  max-width: 28.4375rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 110%;
  letter-spacing: 0.03em;
}
@media (max-width: 64em) {
  .staking-partnership__text {
    font-size: 1.5rem;
  }
}
@media (max-width: 31.25em) {
  .staking-partnership__text {
    font-size: 1.25rem;
  }
}
.staking-partnership__price {
  padding: 1.1875rem 0.9375rem 1.1875rem 1.6875rem;
  position: relative;
  max-width: 27.5rem;
  position: relative;
}
.staking-partnership__price::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: url('../img/border.png') 0 0/100% 100% no-repeat;
}
@media (max-width: 68.75em) {
  .staking-partnership__price {
    padding: 1.125rem 0.875rem 1.125rem 1.25rem;
  }
}
@media (max-width: 31.25em) {
  .staking-partnership__price {
    width: 100%;
    max-width: none;
  }
}
@media (max-width: 21.875em) {
  .staking-partnership__price {
    padding: 0.9375rem;
  }
}
.staking-partnership__price-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-column-gap: 0.9375rem;
  -moz-column-gap: 0.9375rem;
  column-gap: 0.9375rem;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 140%;
  letter-spacing: 0.03em;
}
@media (max-width: 64em) {
  .staking-partnership__price-item {
    font-size: 1.125rem;
  }
}
@media (max-width: 48em) {
  .staking-partnership__price-item {
    font-size: 1rem;
    -webkit-column-gap: 0.625rem;
    -moz-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
}
@media (max-width: 23.125em) {
  .staking-partnership__price-item {
    font-size: 0.875rem;
    -webkit-column-gap: 0.3125rem;
    -moz-column-gap: 0.3125rem;
    column-gap: 0.3125rem;
  }
}
.staking-partnership__price-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.9375rem;
}
@media (max-width: 48em) {
  .staking-partnership__price-amount {
    -webkit-column-gap: 0.625rem;
    -moz-column-gap: 0.625rem;
    column-gap: 0.625rem;
  }
}
@media (max-width: 23.125em) {
  .staking-partnership__price-amount {
    -webkit-column-gap: 0.3125rem;
    -moz-column-gap: 0.3125rem;
    column-gap: 0.3125rem;
  }
}
.staking-partnership__price-label,
.staking-partnership__price-value {
  text-wrap: nowrap;
}
.staking-partnership__price-change {
  font-family: var(--second-family);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 140%;
  letter-spacing: 0.03em;
}
@media (max-width: 64em) {
  .staking-partnership__price-change {
    font-size: 1.125rem;
  }
}
@media (max-width: 48em) {
  .staking-partnership__price-change {
    font-size: 1rem;
  }
}
@media (max-width: 23.125em) {
  .staking-partnership__price-change {
    font-size: 0.875rem;
  }
}
.staking-partnership__button {
  min-width: 14.5rem;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}
@media (max-width: 31.25em) {
  .staking-partnership__button {
    width: 100%;
  }
}

.pools__title {
  margin-bottom: 1.5625rem;
  font-size: 3rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media (max-width: 48em) {
  .pools__title {
    margin-bottom: 0.9375rem;
    font-size: 1.75rem;
  }
}
.pools__title-avo {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  text-shadow: 0 0 34px var(--white);
}
.pools__title-avo span {
  display: inline-block;
  letter-spacing: 0.05em;
}
.pools__list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, 17.5rem);
  gap: 1.25rem;
}
@media (max-width: 48em) {
  .pools__list {
    -ms-grid-columns: minmax(16.875rem, 25rem);
    grid-template-columns: minmax(16.875rem, 25rem);
    gap: 0.625rem;
    justify-content: center;
  }
}
.item-pools {
  padding: 1.875rem 1.125rem 2.0625rem 1.125rem;
}
.item-pools__label {
  display: block;
  margin-bottom: 0.1875rem;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 110%;
  text-transform: uppercase;
  text-shadow: 0 0 34px var(--white);
  text-align: start;
}
.item-pools__profit {
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 110%;
  text-transform: uppercase;
  text-shadow: 0 0 34px var(--white);
  text-align: start;
}
.item-pools__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.9375rem;
}
.item-pools__info-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: var(--second-family);
}
.item-pools__info-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  width: 100%;
}
.item-pools__info-icon {
  width: 1rem;
  height: 1rem;
}
.item-pools__info-value {
  text-align: end;
  width: 100%;
}
.item-pools__info-value--tvl {
  font-weight: 700;
}

.footnotes__content {
  position: relative;
  padding-left: 2.125rem;
}
.footnotes__content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: url('../img/svg/alert.svg') 0 0 no-repeat;
}
.footnotes__text {
  font-family: var(--second-family);
  font-size: 0.875rem;
  line-height: 140%;
}
.footnotes__text:not(:last-child) {
  margin-bottom: 1.25rem;
}
.footnotes__text span {
  font-weight: 600;
}
@media (max-width: 48em) {
  .footnotes__text {
    font-size: 0.75rem;
    line-height: 150%;
  }
}

.my-staking__title {
  margin-bottom: 0.625rem;
  font-family: var(--font-family);
  font-size: 1.625rem;
}
@media (max-width: 48em) {
  .my-staking__head {
    display: none;
  }
}
.my-staking__body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  gap: 0.625rem;
}
@media (max-width: 48em) {
  .my-staking__body {
    -ms-grid-columns: minmax(16.875rem, 25rem);
    grid-template-columns: minmax(16.875rem, 25rem);
    gap: 0.625rem;
    justify-content: center;
  }
}
@media (max-width: 37.5em) {
  .my-staking__body {
    -ms-grid-columns: minmax(16.875rem, 25rem);
    grid-template-columns: minmax(16.875rem, 25rem);
    gap: 0.625rem;
  }
}
.my-staking__items {
  display: -ms-grid;
  display: grid;
  gap: 0.625rem;
  -ms-grid-columns: minmax(15.625rem, 20rem) minmax(9.375rem, 12.8125rem) minmax(3.75rem, 9.875rem)
    minmax(15.625rem, 19.375rem) minmax(8.125rem, 12.5rem);
  grid-template-columns:
    minmax(15.625rem, 20rem) minmax(9.375rem, 12.8125rem) minmax(3.75rem, 9.875rem) minmax(15.625rem, 19.375rem)
    minmax(8.125rem, 12.5rem);
}
@media (max-width: 62.375em) {
  .my-staking__items {
    -ms-grid-columns: minmax(12.5rem, 20rem) minmax(7.5rem, 12.8125rem) minmax(3.75rem, 9.875rem)
      minmax(12.5rem, 19.375rem) minmax(6.875rem, 11.25rem);
    grid-template-columns:
      minmax(12.5rem, 20rem) minmax(7.5rem, 12.8125rem) minmax(3.75rem, 9.875rem) minmax(12.5rem, 19.375rem)
      minmax(6.875rem, 11.25rem);
  }
}
@media (max-width: 48em) {
  .my-staking__items {
    padding: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.9375rem;
  }
}
.my-staking__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 62.375em) {
  .my-staking__item {
    font-size: 0.875rem;
  }
}
@media (max-width: 48em) {
  .my-staking__item {
    gap: 0.625rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (max-width: 21.875em) {
  .my-staking__item {
    font-size: 0.8125rem;
  }
}
.my-staking__item-label {
  display: none;
}
@media (max-width: 48em) {
  .my-staking__item-label {
    display: block;
    max-width: 7.1875rem;
    font-family: var(--second-family);
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 110%;
  }
}
.my-staking__items--head {
  margin-bottom: 0.625rem;
  font-family: var(--second-family);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
}
.my-staking__items--data {
  min-height: 6rem;
}
.my-staking__amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.875rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1.875rem;
}
@media (max-width: 62.375em) {
  .my-staking__amount {
    padding-left: 0.9375rem;
    gap: 0.4375rem;
  }
}
@media (max-width: 48em) {
  .my-staking__amount {
    padding-left: 0;
    gap: 0.4375rem;
  }
}
.my-staking__amount-icon {
  width: 2.0625rem;
}
@media (max-width: 62.375em) {
  .my-staking__amount-icon {
    width: 1.25rem;
  }
}
@media (max-width: 48em) {
  .my-staking__amount-icon {
    width: 1.125rem;
  }
}
@media (max-width: 21.875em) {
  .my-staking__amount-icon {
    width: 0.9375rem;
  }
}
.my-staking__revenue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.my-staking__revenue-avo {
  margin-bottom: 0.1875rem;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 64em) {
  .my-staking__revenue-avo {
    font-size: 1rem;
  }
}
@media (max-width: 62.375em) {
  .my-staking__revenue-avo {
    font-size: 0.875rem;
  }
}
@media (max-width: 48em) {
  .my-staking__revenue-avo {
    font-size: 1rem;
  }
}
@media (max-width: 21.875em) {
  .my-staking__revenue-avo {
    font-size: 0.8125rem;
  }
}
.my-staking__revenue-convert {
  font-size: 0.75rem;
}
@media (max-width: 48em) {
  .my-staking__revenue-convert {
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
  }
}
.my-staking__item-time {
  font-family: var(--second-family);
  font-weight: 500;
  text-transform: uppercase;
}
.my-staking__item-apr {
  font-family: var(--second-family);
  font-weight: 700;
}
.my-staking__action {
  width: 100%;
  padding-right: 1.875rem;
}
@media (max-width: 62.375em) {
  .my-staking__action {
    padding-right: 0.9375rem;
  }
}
@media (max-width: 48em) {
  .my-staking__action {
    padding-right: 0;
  }
}
.my-staking__button {
  min-height: 2.25rem;
  width: 100%;
  font-size: 0.875rem;
}

.pools-partnership__title {
  margin-bottom: 1.25rem;
  font-size: 3rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media (max-width: 62.375em) {
  .pools-partnership__title {
    font-size: 2.375rem;
  }
}
@media (max-width: 48em) {
  .pools-partnership__title {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
  }
}
.pools-partnership__content {
  position: relative;
}
.pools-partnership__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.875rem;
}
.item-pools-partnership {
  overflow: hidden;
}
.item-pools-partnership__label {
  margin-bottom: 0.3125rem;
  display: block;
  font-family: var(--second-family);
  text-transform: uppercase;
}
@media (max-width: 48em) {
  .item-pools-partnership__label {
    font-size: 0.875rem;
  }
}
.item-pools-partnership__content {
  padding: 1.875rem;
  position: relative;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
}
@media (max-width: 73.125em) {
  .item-pools-partnership__content {
    padding: 1.25rem;
  }
}
@media (max-width: 21.875em) {
  .item-pools-partnership__content {
    padding: 0.9375rem;
  }
}
.item-pools-partnership__content::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: 6px;
  border: 2px solid transparent;
  background: radial-gradient(ellipse, rgb(21, 21, 21) 0%, rgba(21, 21, 21, 0.1) 100%),
    radial-gradient(ellipse, rgb(9, 55, 223) 0%, rgba(9, 55, 223, 0.1) 100%),
    radial-gradient(ellipse, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3) 100%);
  background-origin: border-box;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  -webkit-mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #000)) border-box;
  mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0) border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.item-pools-partnership__title {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 62.375em) {
  .item-pools-partnership__title {
    gap: 0.5rem;
  }
}
@media (max-width: 48em) {
  .item-pools-partnership__title._active {
    position: relative;
  }
  .item-pools-partnership__title._active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.0625rem;
    opacity: 0.4;
    background-color: var(--white);
    left: 0;
    bottom: -1.25rem;
  }
}
.item-pools-partnership__image {
  position: relative;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    140deg,
    rgb(3, 61, 44) 0%,
    rgb(47, 123, 90) 16%,
    rgb(47, 123, 90) 35%,
    rgb(40, 82, 59) 100%
  );
}
@media (max-width: 73.125em) {
  .item-pools-partnership__image {
    width: 3.125rem;
    height: 3.125rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__image {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media (max-width: 24.0625em) {
  .item-pools-partnership__image {
    width: 1.875rem;
    height: 1.875rem;
  }
}
.item-pools-partnership__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.375rem;
  height: 1.125rem;
  fill: var(--yellow-text);
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}
@media (max-width: 62.375em) {
  .item-pools-partnership__icon {
    width: 0.875rem;
    height: 0.6875rem;
  }
}
.item-pools-partnership__header {
  margin-right: 3.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  -webkit-transition: max-width 0.3s ease 0s;
  transition: max-width 0.3s ease 0s;
}
@media (min-width: 48.0625em) {
  ._active .item-pools-partnership__header {
    max-width: 36.5%;
    position: relative;
  }
  ._active .item-pools-partnership__header::after {
    content: '';
    position: absolute;
    width: calc(100% + 1.5625rem);
    height: 0.0625rem;
    opacity: 0.4;
    background-color: var(--white);
    left: 0;
    bottom: -1.25rem;
  }
}
@media (max-width: 48em) {
  .item-pools-partnership__header {
    margin-right: 1.25rem;
  }
}
@media (max-width: 21.875em) {
  .item-pools-partnership__header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0.4375rem;
  }
}
.item-pools-partnership__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}
.item-pools-partnership__arrow-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.375rem;
  height: 0.75rem;
  fill: var(--yellow-text);
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}
._active .item-pools-partnership__arrow-icon {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
@media (max-width: 48em) {
  .item-pools-partnership__arrow-icon {
    width: 1.0625rem;
    height: 0.5625rem;
  }
}
.item-pools-partnership__name {
  font-size: 2rem;
}
@media (max-width: 73.125em) {
  .item-pools-partnership__name {
    font-size: 1.75rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__name {
    font-size: 1.25rem;
  }
}
@media (max-width: 24.0625em) {
  .item-pools-partnership__name {
    font-size: 1.125rem;
  }
}
.item-pools-partnership__tvl {
  font-size: 1.5rem;
  text-align: start;
}
@media (max-width: 73.125em) {
  .item-pools-partnership__tvl {
    font-size: 1.25rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__tvl {
    font-size: 1rem;
  }
}
@media (max-width: 24.0625em) {
  .item-pools-partnership__tvl {
    font-size: 0.875rem;
  }
}
.item-pools-partnership__apr {
  margin-bottom: 0.375rem;
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
}
@media (max-width: 73.125em) {
  .item-pools-partnership__apr {
    font-size: 1.125rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__apr {
    font-size: 1rem;
  }
}
@media (max-width: 24.0625em) {
  .item-pools-partnership__apr {
    font-size: 0.875rem;
  }
}
.item-pools-partnership__age {
  font-family: var(--second-family);
  font-size: 1.25rem;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-transform: uppercase;
}
@media (max-width: 73.125em) {
  .item-pools-partnership__age {
    font-size: 1.125rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__age {
    font-size: 1rem;
  }
}
@media (max-width: 24.0625em) {
  .item-pools-partnership__age {
    font-size: 0.875rem;
  }
}
.item-pools-partnership__body {
  transition: all 0.3s ease 0s;
  opacity: 0;
  height: 0;
  padding: 0rem 3.75rem 0rem 4.75rem;
  visibility: hidden;
}
.item-pools-partnership__body.active {
  opacity: 1;
  height: auto;
  padding: 2.5rem 3.75rem 2.8125rem 4.75rem;
  visibility: visible;
}
@media (max-width: 62.375em) {
  .item-pools-partnership__body {
    padding: 0rem 3.75rem 0rem 3rem;
  }
}
@media (max-width: 48em) {
  .item-pools-partnership__body {
    padding: 0rem;
  }
}
@media (max-width: 62.375em) {
  .item-pools-partnership__body.active {
    padding: 2.5rem 3.75rem 2.9375rem 3rem;
  }
}
@media (max-width: 48em) {
  .item-pools-partnership__body.active {
    padding: 2.8125rem 0rem 0.4375rem 0rem;
  }
}
.item-pools-partnership__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 48em) {
  .item-pools-partnership__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1.25rem;
  }
}
.item-pools-partnership__descr {
  -webkit-transform: translate(0px, 20px);
  -ms-transform: translate(0px, 20px);
  transform: translate(0px, 20px);
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}
.active .item-pools-partnership__descr {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.item-pools-partnership__amount-container {
  margin-bottom: 1.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}
.item-pools-partnership__amount {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1.5rem;
}
@media (max-width: 73.125em) {
  .item-pools-partnership__amount {
    font-size: 1.25rem;
  }
}
@media (max-width: 73.125em) and (max-width: 24.0625em) {
  .item-pools-partnership__amount {
    font-size: 1rem;
  }
}
@media (max-width: 73.125em) and (max-width: 21.875em) {
  .item-pools-partnership__amount {
    font-size: 0.875rem;
  }
}
@media (min-width: 48.0625em) {
  .item-pools-partnership__transactions {
    position: absolute;
    z-index: 5;
    width: 40%;
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    opacity: 0;
    visibility: hidden;
    top: 1.875rem;
    right: 3.4375rem;
  }
  .active .item-pools-partnership__transactions {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 73.1875em) {
  .item-pools-partnership__transactions {
    right: 5.625rem;
    width: 43%;
  }
}
@media (max-width: 48em) {
  .item-pools-partnership__transactions {
    -webkit-transform: translate(0px, 20px);
    -ms-transform: translate(0px, 20px);
    transform: translate(0px, 20px);
    -webkit-transition: -webkit-transform 0.5s ease 0s;
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
  }
  .active .item-pools-partnership__transactions {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

.timer__title {
  margin-bottom: 0.375rem;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
}
.timer__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.625rem;
}
.timer__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.25rem;
  font-family: var(--second-family);
}
.timer__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  width: 1.875rem;
  height: 2.25rem;
  background: var(--footer-bg);
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
}
.timer__label {
  font-size: 0.75rem;
}

.transactions {
  padding: 1.25rem 1.25rem 2.125rem 1.25rem;
  border-radius: 12px;
  background: #052418;
}
@media (max-width: 73.125em) {
  .transactions {
    padding: 1.25rem 1.25rem 1.5rem 1.25rem;
  }
}
.transactions__tabs {
  position: relative;
  z-index: 2;
}
.transactions__navigation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.625rem;
}
.transactions__title {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  border-radius: 6px;
  font-size: 1.25rem;
  min-height: 3rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media (max-width: 73.125em) {
  .transactions__title {
    font-size: 1.125rem;
  }
}
@media (max-width: 62.375em) {
  .transactions__title {
    font-size: 1rem;
  }
}
@media (max-width: 21.875em) {
  .transactions__title {
    font-size: 0.875rem;
    min-height: 2.5rem;
  }
}
.transactions__title::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: url('../img/border-tab.png') 0 0/100% 100% no-repeat;
}
.transactions__title._tab-active {
  background: var(--gray-text);
}
.transactions__title._tab-active::after {
  display: none;
}
.transactions__body {
  padding-top: 1.4375rem;
}
@media (max-width: 48em) {
  .transactions__body {
    padding-top: 1.0625rem;
  }
}
.transactions__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.transactions__label {
  margin-bottom: 0.3125rem;
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
}
.transactions__control {
  margin-bottom: 1.25rem;
  padding: 0.625rem 0.9375rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 3.75rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.4);
}
.transactions__control::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: url('../img/bg-control.png') 0 0/100% 100% no-repeat;
}
@media (max-width: 62.375em) {
  .transactions__control {
    min-height: 3.125rem;
  }
}
@media (max-width: 21.875em) {
  .transactions__control {
    font-size: 1rem;
    min-height: 3.125rem;
  }
}
.transactions__input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
}
.transactions__input:focus {
  outline: none;
}
.transactions__btn-max {
  font-family: var(--second-family);
}
.transactions__button {
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  width: 100%;
  width: 14.375rem;
}
@media (max-width: 31.25em) {
  .transactions__button {
    width: 100%;
  }
}

.footer {
  padding-top: 2.1875rem;
  padding-bottom: 3.4375rem;
  -webkit-box-shadow: 0 9px 32px 0 rgba(89, 92, 219, 0.05);
  box-shadow: 0 9px 32px 0 rgba(89, 92, 219, 0.05);
  background: var(--footer-bg);
}
@media (max-width: 48em) {
  .footer {
    padding-bottom: 1.75rem;
  }
}
.footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.4375rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 48em) {
  .footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.footer__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 3.875rem;
}
.footer__text {
  margin-top: 0.4375rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--yellow-text);
  max-width: 11.25rem;
  line-height: 1.2;
  position: relative;
}
.footer__text::after {
  content: '';
  position: absolute;
  width: 0.0625rem;
  height: 80%;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  left: -1.5rem;
}
@media (max-width: 48em) {
  .footer__text {
    font-size: 0.75rem;
    height: 100%;
  }
}
.footer__about {
  font-size: 0.875rem;
  text-transform: uppercase;
  position: relative;
}
.footer__about::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.1875rem;
  width: 100%;
  height: 0.0625rem;
  background-color: #fff;
  -webkit-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
@media (any-hover: hover) {
  .footer__about:hover::after {
    opacity: 0;
  }
}
@media (max-width: 48em) {
  .footer__about {
    font-size: 0.75rem;
  }
}
